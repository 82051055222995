<template src="./AllFormsAcademic.html">
</template>

<script>
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import coursesService from "@/services/Courses";

export default {
  name: "AllFormsAcademic",
  components: {
    ArrobaMedellinSeeker
  },
  data() {
    return {
      allGeneralCourses: [],
      tableDataMembers: [
        {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        },
      ],
      tableDataCategories: [
        {
          id: "1",
          name: "Comunicación social",
          description: "Comunicación",
          image: "../../assets/img/default.jpeg",
          icon: '',
          edit: '',
        }, {
          id: "1",
          name: "Comunicación social",
          description: "Comunicación",
          image: "../../assets/img/default.jpeg",
          icon: '',
          edit: '',
        }, {
          id: "1",
          name: "Comunicación social",
          description: "Comunicación",
          image: "../../assets/img/default.jpeg",
          icon: '',
          edit: '',
        }, {
          id: "1",
          name: "Comunicación social",
          description: "Comunicación",
          image: "../../assets/img/default.jpeg",
          icon: '',
          edit: '',
        }, {
          id: "1",
          name: "Comunicación social",
          description: "Comunicación",
          image: "../../assets/img/default.jpeg",
          icon: '',
          edit: '',
        },
      ],
      searchResults: [
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
      ],
      infoFormsAcademic: [
        {
          name: "Nombre de curso muy largo",
          institution: 'institución',
          members: 999
        }, {
          name: "Nombre de curso muy largo",
          institution: 'institución',
          members: 999
        }, {
          name: "Nombre de curso muy largo",
          institution: 'institución',
          members: 999
        }, {
          name: "Nombre de curso muy largo",
          institution: 'institución',
          members: 999
        },
      ],
      namesCoursesWithoutEnrolment:[],
      countCoursesWithoutEnrolment: null,
      allCoursesFormNames: [],
      errorMessage: false,
    }
  },
  methods:{
    async getAllCoursesForm(){
      try {
        const response = await coursesService.getAllCoursesForm()
        console.log('getAllCoursesForm', response);
      }catch (e) {
        console.log('e',e);
      }
    },
    async countCoursesHaveNotEnrolment(){
      try {
        this.countCoursesWithoutEnrolment = await coursesService.countCoursesHaveNotEnrolment()
        console.log('countCoursesHaveNotEnrolment', this.countCoursesWithoutEnrolment);
      }catch (e) {
        console.log('e',e);
      }
    },
    async countCoursesHaveNotEnrolmentNames(){
      try {
        this.namesCoursesWithoutEnrolment = await coursesService.countCoursesHaveNotEnrolmentNames()
        console.log('namesCoursesWithoutEnrolment', this.namesCoursesWithoutEnrolment);
      }catch (e) {
        console.log('e',e);
      }
    },
    async getAllCoursesFormNames(){
      try {
        this.allCoursesFormNames= await coursesService.getAllCoursesFormNames()
        console.log('allCoursesFormNames', this.allCoursesFormNames);
      }catch (e) {
        console.log('e',e);
      }
    },

    async searchByWord(word){
      try {
        this.allCoursesFormNames = await coursesService.getFormEnrollCoursesByWord(word)
      }catch (e) {
        console.log('e',e);
      }
    },
    deleteFormEnrollCourse(courseId) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar formulario',
        message: '¿Estás seguro que deseas eliminar este formulario?',
        type: 'is-warning',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        size: 'is-small',
        onConfirm: async () => {
          try {
            await coursesService.deleteFormEnrollCourse(courseId);
            await this.getAllCoursesFormNames();
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Formulario eliminado exitosamente',
              position: 'is-bottom',
              type: 'is-success'
            })
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: e.response.data.message,
              position: 'is-bottom',
              type: 'is-danger'
            })
          }
        }
      })
    },
    async getAllGeneralCourses() {
      try {
        this.allGeneralCourses = await coursesService.getGeneralCoursesList();
      } catch (e) {
        console.log('e', e);
      }
    },
  },
  mounted() {
    this.getAllCoursesForm();
    this.countCoursesHaveNotEnrolment();
    this.countCoursesHaveNotEnrolmentNames();
    this.getAllCoursesFormNames();
    this.getAllGeneralCourses();
  }

}
</script>

<style scoped lang="scss" src="./AllFormsAcademic.scss">
</style>
